import React from "react";
import TextLoop from "react-text-loop";

const sliderContent = {
  coname: "MindHive",
  name: "Bobby Larson, Founder & Principal Solutions Architect",
  description: `Welcome to the realm of limitless possibilities, where advanced generative AI technologies, including Large Language Models (LLMs) such as ChatGPT, form the cornerstone of our solutions.`,
  btnText: " My Work",
  btnText2: "Contact Me",
};

const SliderFour = () => {
  return (
    <>
      {/*  Home Banner */}
      <section
        id="home"
        className="home-banner home-banner-two"
        style={{
          backgroundImage: `linear-gradient(to bottom, rgba(0,0,0,0.65) 0%,rgba(0,0,0,0.65) 100%), url(${
            process.env.PUBLIC_URL + "img/slider/home-professional.png"
          })`,
        }}
      >
        <div className="container">
          <div className="row full-screen align-items-center">
            <div className="col-lg-12">
              <div className="type-box">
                <h6 data-aos="fade-up" data-aos-duration="1200">
                  {sliderContent.name}
                </h6>
                <h1
                  className="font-alt"
                  data-aos="fade-up"
                  data-aos-duration="1200"
                  data-aos-delay="100"
                >
                  {sliderContent.coname}
                </h1>

                <div
                  data-aos="fade-up"
                  data-aos-duration="1200"
                  data-aos-delay="200"
                >
                  <TextLoop>
                    <p className="loop-text lead"> Software Engineer</p>
                    <p className="loop-text lead"> ML Ops & LLM Ops Architect</p>
                    <p className="loop-text lead"> Prompt Engineer</p>
                    <p className="loop-text lead"> Cognitive Services Architect</p>
                    <p className="loop-text lead"> IoT & Edge Solutions Architect</p>
                    <p className="loop-text lead"> AI Integrations Developer</p>
                  </TextLoop>
                </div>

                <p
                  className="desc"
                  data-aos="fade-up"
                  data-aos-duration="1200"
                  data-aos-delay="300"
                >
                  {sliderContent.description}
                </p>
                <div className="d-flex btn-wrapper">
                  <a
                    className="px-btn px-btn-theme mr-4"
                    href="https://goo.gl/xC8XUs"
                    target="_blank"
                    data-aos="fade-up"
                    data-aos-duration="1200"
                    data-aos-delay="400"
                  >
                    {sliderContent.btnText}
                  </a>
                  <a
                    className="px-btn btn-outline"
                    href="#contactus"
                    data-aos="fade-up"
                    data-aos-duration="1200"
                    data-aos-delay="500"
                  >
                    {sliderContent.btnText2}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Container*/}
        <div className="go-to go-to-next">
          <a href="#about">
            <span></span>
          </a>
        </div>
      </section>

      {/* End Home Banner  */}
    </>
  );
};

export default SliderFour;
