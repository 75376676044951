import React from "react";

const ServiceContent = [
  {
    icon: "icon-lightbulb",
    title: "AI Strategy Consulting",
    descriptions: `We help organizations navigate the complex AI landscape, providing strategic guidance on AI adoption and integration, and ensuring alignment with business goals.`,
    delayAnimation: "",
  }, 
  {
    icon: "icon-upload",
    title: "LLM and Generative AI Solutions",
    descriptions: `Specializing in Large Language Models and generative AI, we design, deploy, and manage advanced AI solutions that drive automation, enhance decision-making, and unlock new opportunities.`,
    delayAnimation: "100",
  },
  {
    icon: "icon-compass",
    title: "MLOps, AIOps, and LLMOps Expertise",
    descriptions: `We leverage our deep understanding of operationalization to efficiently design, deploy, and manage AI systems, ensuring seamless integration into your existing tech stack.`,
    delayAnimation: "200",
  },
  {
    icon: "icon-beaker",
    title: "Cognitive Services Development",
    descriptions: `Our expertise in cognitive services enables us to deliver solutions that understand, interpret, and interact in a natural manner, improving customer experiences and driving operational efficiency.`,
    delayAnimation: "300",
  },
  {
    icon: "icon-presentation",
    title: "AI Education and Change Management",
    descriptions: `We demystify AI for your team, guiding them through the transition and ensuring they leverage the technology to its fullest potential, all while managing the change effectively.`,
    delayAnimation: "400",
  },
  {
    icon: "icon-target",
    title: "AI-Driven Business Transformation",
    descriptions: `We harness the power of generative AI to drive business transformation, helping organizations reshape processes, improve efficiency, enhance customer engagement, and unlock new growth opportunities.`,
    delayAnimation: "500",
  }
];
export default function Service() {
  return (
    <>
      <div className="row">
        {ServiceContent.map((val, i) => (
          <div
            className="col-md-6 col-lg-4 my-3"
            key={i}
            data-aos="fade-right"
            data-aos-duration="1200"
            data-aos-delay={val.delayAnimation}
          >
            <div className="feature-box-01">
              <div className="icon">
                <i className={`icon ${val.icon}`}></i>
              </div>
              <div className="feature-content">
                <h5>{val.title}</h5>
                <p>{val.descriptions}</p>
              </div>
            </div>
            {/* End .feature-box-01 */}
          </div>
        ))}
      </div>
    </>
  );
}
