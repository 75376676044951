import React from "react";

const ContactInfo = () => {
  return (
    <>
      <div className="contact-info">
        <h4>What’s your story?</h4>
        <h5>Get in touch</h5>
        <p>
          We are available for freelancing if the right project comes along.
        </p>

        <ul>
          <li className="media">
            <i className="icon icon-map"></i>
            <span className="media-body">
              Denver, Colorado, United States Of America 80215.
            </span>
          </li>
          {/* End li */}

          <li className="media">
            <i className="icon icon-envelope"></i>
            <span className="media-body">bobby@mindhive.tech</span>
          </li>
          {/* End li */}

          <li className="media">
            <i className="icon icon-phone"></i>
            <span className="media-body">+1 (720) 663-8656</span>
          </li>
          {/* End li */}

          <li className="media">
            <i className="icon icon-glode"></i>
            <span className="media-body">
              <a href="https://www.linkedin.com/in/bobbyl/">LinkedIn</a>
            </span>
          </li>
          {/* End li */}

        </ul>
      </div>
      {/* End .contact-info */}
    </>
  );
};

export default ContactInfo;
