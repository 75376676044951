import React from "react";
import Social from "../Social";
//import Testimonials from "../testimonial/TestimonialAnimation";
import Services from "../service/ServiceAnimation";
//import Awards from "../award/AwardsAnimation";

const AboutThree = () => {
  return (
    <>
      <section id="about" className="section theme-light dark-bg">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div
              className="col-md-6 col-lg-4"
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              <div className="about-me">
                <div className="img">
                  <div className="img-in">
                    <img src="img/about/bobby.jpg" alt="about" />
                  </div>
                  <Social />
                  {/* End social icon */}
                </div>
                {/* End img */}
                <div className="info">
                  <p>Founder, Solutions Architect</p>
                  <h3>Bobby Larson</h3>
                </div>
                {/* End info */}
              </div>
              {/* End about-me */}
            </div>
            {/* End col */}

            <div
              className="col-lg-7 ml-auto"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="200"
            >
              <div className="about-info">
                <div className="title">
                  <h3>Navigating the AI Landscape</h3>
                  <h5>Our Services and Expertise</h5>
                </div>
                <div className="about-text">
                  <p>
                    As a seasoned Cloud Solutions Architect who specializes in generative AI, large language models (LLMS) and cognitive solutions, I possess the unique ability to harness the transformative power of AI, ensuring that your organization stays at the forefront of innovation.
                  </p>

                  <p>
                    In an era where data is king and efficiency is paramount, our expertise lies in seamlessly integrating advanced AI solutions into your existing tech stack. Leveraging a deep understanding of AIOps, MLOps, and LLMOps, we deftly navigate the complexities of designing, deploying, and managing AI systems. Our proficiency in cognitive services ensures we deliver solutions that not only understand, interpret, and interact in a natural manner, but also learn and improve over time, driving automation and decision-making to unprecedented levels.
                  </p>

                  <p>
                    But our commitment extends beyond just building and integrating AI solutions. We understand that adopting AI technology can be a significant transition for organizations, and we&apos;re here to guide you every step of the way. As your trusted AI advisor, we help demystify the complexities and potential of AI, ensuring your team understands and leverages the technology to its fullest. Our goal is to help you see AI not as a challenge, but as a valuable tool for catalyzing growth and innovation.
                  </p>

                  <p>
                    In both advisory and engineering capacities, we bring immense value to organizations. Our holistic approach ensures that we not only understand your unique challenges but also align our AI solutions with your strategic objectives. This alignment translates into tangible benefits – improved efficiency, cost reductions, enhanced customer experiences, and the capability to unlock new opportunities.
                  </p>

                  <p>
                    With us, you&apos;re not just embracing the future; you&apos;re shaping it. Let us help you unlock the full potential of generative AI and large language models, driving a new era of growth and innovation for your organization.
                  </p>
                </div>
                <div className="info-list">
                  <div className="row">
                    <div className="col-sm-6">
                      <ul>
                        <li>
                          <label>Name: </label>
                          <span>Bobby Larson</span>
                        </li>
                        <li>
                          <label>Address: </label>
                          <span>Denver</span>
                        </li>
                        <li>
                          <label>Freelance: </label>
                          <span><a href="#contactus">Available</a></span>
                        </li>
                      </ul>
                    </div>
                    <div className="col-sm-6">
                      <ul>
                        <li>
                          <label>Phone: </label>
                          <span>+1 (720) 663-8656</span>
                        </li>
                        <li>
                          <label>Email: </label>
                          <span>bobby@mindhive.tech</span>
                        </li>
                        <li>
                          <label>LinkedIn: </label>
                          <span><a href="http://linkedin.com/in/bobbyl">bobbyl</a></span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* End col */}
          </div>

          {/* separated */}
          <div
            className="separated"
            style={{
              backgroundImage: `url(${
                process.env.PUBLIC_URL + "img/border-dark.png"
              })`,
            }}
          ></div>
          {/* End separated */}
          <div className="title">
            <h3>What we do?</h3>
          </div>
          <Services />
          {/* End .row */}

          {/* separated */}
          {/*
          <div
            className="separated"
            style={{
              backgroundImage: `url(${
                process.env.PUBLIC_URL + "img/border-dark.png"
              })`,
            }}
          ></div>
          */}
          {/* End separated */}

          {/*
          <div className="title">
            <h3>Awards.</h3>
          </div>
          <Awards />
          */}
          {/* End Awards */}

          {/* separated */}
          {/*
          <div
            className="separated"
            style={{
              backgroundImage: `url(${
                process.env.PUBLIC_URL + "img/border-dark.png"
              })`,
            }}
          ></div>
          */}
          {/* End separated */}

          {/*
          <div className="title">
            <h3>Testimonials.</h3>
          </div>
          <Testimonials />
          */}
          {/* End Testimonaial */}
        </div>
      </section>
    </>
  );
};

export default AboutThree;
