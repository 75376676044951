import React from "react";

const Map = () => {
  return (
    <>
      <div className="google-map">
        <div className="embed-responsive embed-responsive-21by9">
          <iframe
            className="embed-responsive-item"
            title="location title"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d24543.950538241526!2d-105.13651959693738!3d39.73978973492617!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x876b840265969f01%3A0x379e58c6205bace1!2sDenver%2C%20CO%2080215!5e0!3m2!1sen!2sus!4v1675900287414!5m2!1sen!2sus"
          />
        </div>
      </div>
      {/* End google-map */}
    </>
  );
};

export default Map;
